import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  fill: "none",
  d: "M3 19V10.07C2.99995 9.7408 3.08116 9.41669 3.23641 9.12642C3.39166 8.83615 3.61617 8.58868 3.89 8.40599L10.89 3.73999C11.2187 3.52071 11.6049 3.40369 12 3.40369C12.3951 3.40369 12.7813 3.52071 13.11 3.73999L20.11 8.40599C20.3838 8.58868 20.6083 8.83615 20.7636 9.12642C20.9188 9.41669 21 9.7408 21 10.07V19M3 19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19M3 19L9.75 14.5M21 19L14.25 14.5M9.75 14.5L3 9.99999M9.75 14.5L10.89 15.26C11.2187 15.4793 11.6049 15.5963 12 15.5963C12.3951 15.5963 12.7813 15.4793 13.11 15.26L14.25 14.5M14.25 14.5L21 9.99999",
  stroke: "currentColor",
  "stroke-width": "2",
  "stroke-linecap": "round",
  "stroke-linejoin": "round"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("path", _hoisted_1))
}